// @flow
import React, {useEffect} from "react";
import "./GoogleSearch.scss";

function GoogleSearch() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://cse.google.com/cse.js?cx=dfdf26c792491a3da";
        script.async = true;
        document.getElementById("googleSearchBox").appendChild(script);
    }, []);
    return (
        <div className="googleSearch" id={"googleSearchBox"}>
            <div className="gcse-searchbox"></div>
        </div>
    );
}

export default GoogleSearch;
