// @flow

import React from "react";
import type {News} from "../model/News";
import {getTeaserText, toLink} from "../../helper/stringUtils";

type NewsTileProps = {
    news: News,
}

function NewsTile(props: NewsTileProps) {
    const {news} = props;
    const keywords = news.keywords.split(",")
        .sort((a, b) => {
            return ["bitcoin", "crypto", "cryptocurrency", "cryptocurrencies"].includes(a)
                ? 1 : -1;
        })
        .slice(0, 4);
    return (
        <article className="newsTile">
            <figure className={"tileImage"}>
                <a href={`/articles/${toLink(news.headline)}`}
                   title={news.headline} className={"headline"}>
                    <img src={news.img} title={news.headline} alt={news.headline}/>
                </a>
            </figure>
            <div className="tileBody">
                <div className="teaser">
                    <div className="keywords">
                        {keywords.map((it, index) =>
                            <span key={it}>{it}{index === (keywords.length - 1) ? "" : ", "}</span>)}
                    </div>
                    <a href={`/articles/${toLink(news.headline)}`}
                       title={news.headline} className={"headline"}>
                        <h2>
                            <span className="sub">{news.subheadline}</span>
                            <span className="main">{news.headline}</span>
                        </h2>
                    </a>
                    <p className={"teaserText"}
                       dangerouslySetInnerHTML={{__html: getTeaserText(news.content)}}>

                    </p>
                </div>
            </div>

        </article>
    );
}

export default NewsTile;
