import React, {useEffect} from "react";
import './ArchivePage.scss';
import NewsTile from "../mainpage/NewsTile";
import SidebarItem from "../sidebar/SidebarItem";
import SmallNewsTile from "../mainpage/SmallNewsTile";
import type {News} from "../model/News";
import {Helmet} from "react-helmet";
import MyWordCloud from "../sidebar/MyWordCloud";
import GoogleSearch from "../sidebar/GoogleSearch";
import {getMonthString} from "../../helper/dateUtils";
import {toLink} from "../../helper/stringUtils";
import NewsArchive from "../sidebar/NewsArchive";
import BigSidebarItem from "../sidebar/BigSidebarItem";

type ArchivePageProps = {
    path: string,
    pageContext: {
        data: News[],
        recent: News[],
        monthes: Date[],
        wordCloud: [string, number][],
    };
}

function ArchivePage(props: ArchivePageProps) {
    const news = (props.pageContext.data || []);
    const recent = props.pageContext.recent || [];
    const monthString = getMonthString((news.length > 0) ? news[0].date : Date.now(), "en");

    useEffect(() => {
        if (window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            document.querySelectorAll(".light-box")
                .forEach(it => it.remove());
        } else {
            document.querySelectorAll(".dark-box")
                .forEach(it => it.remove());
        }
        if (document) {
            const adsListCount = Math.ceil(Math.min(news.length - 4, 0) / 20)
            for (let i = 0; i < (2 + (news.length > 4 ? 1 : 0) + adsListCount); i++) {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            }
        }
    }, []);

    return <div className={"archive"}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>BtcLeaks | Crypto News Archive for {monthString}.</title>
            <link rel="canonical" href={`https://btcleaks.com/${toLink(monthString)}`}/>
            <meta name="description"
                  content={`The latest news and informations about Crypto, Bitcoin and ${monthString}`}/>
            <meta name="keywords" content={`Crypto, Bitcoin, News, Overview`}/>
            <meta property="og:title" content={`BtcLeaks | Archive with Crypto News from ${monthString}`}/>
            <meta property="og:description"
                  content={`BtcLeaks News Archive with Crypto, Bitcoin and DeFi news from ${monthString}`}/>
            <meta property="og:image" content="http://btcleaks.com/img_00306.png"/>
            <meta property="og:url" content={`https://btcleaks.com/archive/${toLink(monthString)}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:locale" content="en_GB"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="530"/>
            <meta property="og:image:alt" content={"BtcLeaks.com"}/>
            <meta property="og:image:secure_url" content="https://btcleaks.com/img_00306.png"/>
            <meta property="og:image:type" content="image/png"/>
            <meta property="og:site.name" content={"btcLeaks.com"}/>
            <meta property="article:publisher" content="https://btcLeaks.com"/>
            <meta property="article:author" content="BtcLeaks.com"/>
            <meta name="twitter:site" content="@BtcLeaks"/>
            <meta name="twitter:creator" content="Saitama"/>
            <meta name="twitter:card" content="summary"/>
            <script type="application/ld+json">{`
                {
                    "mainEntityOfPage": { 
                        "@type": "WebPage",
                        "@id": "btcleaks.com"
                    },
                    "description": "BtcLeaks News Archive with Crypto, Bitcoin and DeFi news from ${monthString}",
                    "dateModified": "${new Date().toISOString()}",
                    "datePublished": "${new Date().toISOString()}",
                    "headline": "BtcLeaks | Archive with Crypto News from ${monthString}",
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "publisher": {
                        "@type": "Organization",
                        "name": "btcLeaks.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://btcleaks.com/image_00306.png"
                        }   
                    }
                }`
            }</script>
        </Helmet>
        <div className={"contentWrapper"}>
            <div className="column1">
                <div className="content">
                    <div className="gcse-searchresults"/>
                    {news && news.length > 0
                        ? <section className="mainSection">
                            <h1>News Archive {monthString}</h1>
                            {news
                                .filter((it, index) => index < 4)
                                .map((it, i) => <NewsTile news={it} key={"over_" + i}/>)}
                            {news
                                .filter((it, index) => index > 4)
                                .map((it, i) => <React.Fragment>
                                    <SmallNewsTile news={it} key={"overw_" + i}/>
                                    {(i%20 === 0) && <article style={{marginBottom: "24px", overflow: "hidden"}}>
                                        <ins className="adsbygoogle dark-box"
                                             style={{display: "block"}}
                                             data-ad-format="fluid"
                                             data-ad-layout-key="-gx-y+aj-9s-iz"
                                             data-ad-client="ca-pub-6013495598121737"
                                             data-ad-slot="1313212288"/>
                                        <ins className="adsbygoogle light-box"
                                             style={{display: "block"}}
                                             data-ad-format="fluid"
                                             data-ad-layout-key="-hf-1a+ae-9a-gl"
                                             data-ad-client="ca-pub-6013495598121737"
                                             data-ad-slot="9324119159"/>
                                    </article>}
                                </React.Fragment>)}
                        </section>
                        : <section className={"nothingFound"}>
                            No news in {monthString} found.
                        </section>
                    }
                </div>
            </div>
            <aside className={"column2"}>
                <div className="sidebar">
                    <NewsArchive monthes={props.pageContext.monthes}/>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <ins className="adsbygoogle"
                             style={{display: "block"}}
                             data-ad-client="ca-pub-6013495598121737"
                             data-ad-slot="9302285469"
                             data-ad-format="auto"
                             data-full-width-responsive="true"/>
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        {recent.length > 0 &&
                            <div className={"sidebarHeadline"}>Most views</div>}
                        {recent.slice(0, 1)
                            .map((it, i) => <BigSidebarItem key={"rele_big_" + i} news={it}/>)}
                        {recent.slice(1, 100)
                            .map((it, i) => <SidebarItem key={"rele_" + i} news={it}/>)}
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <ins className="adsbygoogle"
                             style={{display: "block"}}
                             data-ad-client="ca-pub-6013495598121737"
                             data-ad-slot="9302285469"
                             data-ad-format="auto"
                             data-full-width-responsive="true"/>
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <div className={"sidebarHeadline"}>
                            <span>Search</span>
                        </div>
                        <GoogleSearch/>
                        <MyWordCloud wordCloud={props.pageContext.wordCloud}/>
                    </div>
                </div>
            </aside>
        </div>
    </div>;
}

export default ArchivePage;
