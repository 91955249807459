// @flow
import React from "react";
import {toLink} from "../../helper/stringUtils";
import "./NewsArchive.scss";
import {getMonthString} from "../../helper/dateUtils";

function NewsArchive(props: { monthes: Date[] }) {
    return <div className="archiveSidebar">
        <div className="headline">
            Archive
        </div>
        {(props.monthes || [])
            .map(it => <div className={"row"}>
                <a href={"/archive/" + toLink(getMonthString(it, "en"))} title={"News from " + getMonthString(it, "en")}>
                    <div>
                        <span>{getMonthString(it, "en")}</span>
                    </div>
                </a>
            </div>)
        }
    </div>;
}

export default NewsArchive;
