// @flow
import React from "react";
import type {News} from "../model/News";
import {toLink} from "../../helper/stringUtils";

type NewsTileProps = {
    news: News,
}

function NewsTile(props: NewsTileProps) {
    const {news} = props;
    const keywords = news.keywords.split(",")
        .sort((a,b) => {
            return ["bitcoin", "crypto", "cryptocurrency", "cryptocurrencies"].includes(a)
                ? 1 : -1;
        })
        .slice(0, 4);
    return (
        <article className="smallNewsTile">
            <figure className={"tileImage"}>
                <a href={`/articles/${toLink(news.headline)}`}
                   title={news.headline} className={"headline"}>
                    <figure className={"imgWrapper"}>
                        <img src={news.img.startsWith("http") ? news.img : "/small" + news.img} title={news.headline} alt={news.headline}/>
                    </figure>
                </a>
            </figure>
            <div className="tileBody">
                <div className="keywords">
                    {keywords.map((it, index) =>
                        <span key={it}>{it}{index === (keywords.length-1) ? "" : ", "}</span>)}
                </div>
                <a href={`/articles/${toLink(news.headline)}`}
                   title={news.headline} className={"headline"}>
                    <h3>
                        <span className="sub">{news.subheadline}</span>
                        <span className="main">{news.headline}</span>
                    </h3>
                </a>
            </div>

        </article>
    );
}

export default NewsTile;
